import React, { createContext, FC, ReactNode, useState } from "react";

export const GameModeContext = createContext({
  num: 3,
  setNum: (_: number) => {},
});
const GameModeStore: FC<{ children: ReactNode }> = ({ children }) => {
  const [num, setNum] = useState(3);
  return (
    <GameModeContext.Provider value={{ num, setNum }}>
      {children}
    </GameModeContext.Provider>
  );
};

export default GameModeStore;
