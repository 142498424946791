import { FC } from "react";
import { useNavigate } from "react-router-dom";

const Top: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="h-100">
      <div className="d-flex flex-column text-center justify-content-center h-100">
        <h1 className="mb-5">ヒット アンド ブロー</h1>
        <div>
          <button
            type="button"
            className="btn btn-primary ps-5 pe-5"
            onClick={() => {
              navigate("/select-mode");
            }}
          >
            ゲーム開始
          </button>
        </div>
        <div className="mt-5">
          <p className="text-center m-0 p-1 text-muted small">
            © 2022 Usalabo, inc
          </p>
        </div>
      </div>
    </div>
  );
};

export default Top;
