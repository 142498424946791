import { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GameModeStore from "./components/game-mode-store/GameModeStore";
import Top from "./components/pages/Top/Top";
import SelectMode from './components/pages/SelectMode/SelectMode';
import Game from "./components/pages/Game/Game";

const App: FC = () => {
  return (
    <BrowserRouter>
      <GameModeStore>
        <Routes>
          <Route path={`/`} element={<Top />} />
          <Route path={`/select-mode`} element={<SelectMode />} />
          <Route path={`/game`} element={<Game />} />
        </Routes>
      </GameModeStore>
    </BrowserRouter>
  );
};

export default App;
