import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEllipsisV,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FC, useEffect, useState, useRef, ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { GameModeContext } from "../../game-mode-store/GameModeStore";
import "./Game.scss";

type ColorType =
  | "red"
  | "orange"
  | "yellow"
  | "yellow-green"
  | "dark-green"
  | "sky-blue"
  | "blue"
  | "pink"
  | "purple"
  | "navy";

const ColorDef: ColorType[] = [
  "red",
  "orange",
  "yellow",
  "yellow-green",
  "dark-green",
  "sky-blue",
  "blue",
  "pink",
  "purple",
  "navy",
];

const Overlay: FC<{
  show?: boolean;
  children: ReactNode;
  color?: "light" | "dark";
}> = ({ show, children, color }) => {
  return (
    <div className={`overlay${show ? " show" : ""}${color ? ` ${color}` : ""}`}>
      {children}
    </div>
  );
};

const Game: FC = () => {
  const bottomRef = useRef<any>(null);

  const { num } = useContext(GameModeContext);

  const navigate = useNavigate();
  const [gameState, setGameState] = useState<"playing" | "clear" | "gameover">(
    "playing"
  );
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [showVersion, setShowVersion] = useState<boolean>(false);
  const [version] = useState<string>(process.env.REACT_APP_VERSION || "0.0.0");
  const [correctDots, setCorrectDots] = useState<{ color?: ColorType }[]>([]);
  const [cursor, setCursor] = useState<number>(0);
  const [selectedDots, setSelectedDots] = useState<{ color?: ColorType }[]>([]);
  const [results, setRetuls] = useState<
    { selectedDots: { color?: ColorType }[]; hitCnt: number; blowCnt: number }[]
  >([]);

  useEffect(() => {
    initCorrectDots(num);
    clearSelectedDots(num);
    clearResults();
    setGameState("playing");
  }, [num]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [results]);

  const initCorrectDots = (num: number) => {
    const selected: ColorType[] = [];
    const color = [...ColorDef];
    for (let idx = 0; idx < num; idx++) {
      const selectedIdx = Math.floor(Math.random() * (color.length - 1));
      selected.push(color[selectedIdx]);
      color.splice(selectedIdx, 1);
    }
    setCorrectDots(selected.map((color) => ({ color })));
  };

  const clearSelectedDots = (num: number) => {
    setSelectedDots(
      new Array<{ color?: ColorType }>(num)
        .fill({})
        .map(() => ({ color: undefined }))
    );
    setCursor(0);
  };

  const clearResults = () => {
    setRetuls([]);
  };

  // const dump = (data: { color?: ColorType }[]) => {
  //   return data.map((e) => e.color).join(",");
  // };

  const isSubmit = () => {
    for (const dot of selectedDots) {
      if (dot.color === undefined) {
        return false;
      }
    }
    return true;
  };

  const submit = () => {
    // console.log("### Submit Check");
    // console.log("correct : ", dump(correctDots));
    // console.log("selected: ", dump(selectedDots));
    let hitCnt = 0;
    let blowCnt = 0;
    for (const [index, selectedDot] of selectedDots.entries()) {
      if (correctDots[index].color === selectedDot.color) {
        hitCnt++;
        continue;
      }
      if (
        correctDots.filter(
          (correctDot) => correctDot.color === selectedDot.color
        ).length > 0
      ) {
        blowCnt++;
      }
    }
    // console.log("hit: ", hitCnt, "  blow: ", blowCnt);
    setRetuls((result) => {
      const newResult = [...result];
      newResult.push({ selectedDots, hitCnt, blowCnt });
      return newResult;
    });
    if (hitCnt === num) {
      setGameState("clear");
      return;
    }
    if (results.length >= 8 - 1) {
      setGameState("gameover");
      return;
    }
    clearSelectedDots(num);
  };

  return (
    <div className="game container">
      <div className="game-help">
        <h2 className="mb-4">ヒット アンド ブロー</h2>
        <p>
          おっと、こんなところに！
          <br />
          いい感じのスペースがあるのでゲームの説明を書かせてもらいますね。
        </p>
        <ol>
          <li>コンピューターがランダムで選んだ{num}つの色を当てましょう。</li>
          <li>
            重複しない{num}つの色を選択して <FontAwesomeIcon icon={faCheck} />{" "}
            を押すと、正解の色と比較した結果が返ってきます。
          </li>
          <li>
            <b>色</b>と<b>位置</b>が一致すれば<b>Hit</b>。<b>色</b>
            だけが一致した場合は<b>Blow</b>。
          </li>
          <li>
            <b>Hit</b>と<b>Blow</b>の個数をヒントに色を当てましょう。
          </li>
          <li>8回以内に当てられなければゲームオーバーです。</li>
        </ol>
        <p>ルールは簡単ですよね？早速ですがプレイしてみましょう！</p>
      </div>
      <div className="game-frame">
        <div className="p-0 game-main">
          <div className="results">
            {results.map((result, idx) => (
              <div key={idx} className="d-flex align-items-center m-1">
                <div className="d-flex flex-column hint">
                  <div className="small">Hit: {result.hitCnt}</div>
                  <div className="small">Blow: {result.blowCnt}</div>
                </div>
                <div className="d-flex justify-content-evenly flex-grow-1">
                  {result.selectedDots.map(({ color }, idx) => (
                    <span key={idx} className={`dot dot-${color}`}></span>
                  ))}
                </div>
              </div>
            ))}
            <div ref={bottomRef} />
          </div>
          <div className="input-area">
            <div className="selected-viewer">
              {selectedDots.map((dot, idx) => {
                return (
                  <span
                    key={idx}
                    className={`dot${dot.color ? " dot-" + dot.color : ""}${
                      cursor === idx ? " active" : ""
                    }`}
                    onClick={() => setCursor(idx)}
                  ></span>
                );
              })}
            </div>
            <div className="input-form">
              <div className="entry">
                {ColorDef.map((color, idx) => (
                  <span
                    key={idx}
                    className={`dot dot-${color}`}
                    onClick={() => {
                      setSelectedDots((val) => {
                        const newDots = [...val];
                        newDots
                          .filter((dot) => dot.color === color)
                          .forEach((dot) => {
                            dot.color = undefined;
                          });
                        newDots[cursor].color = color;
                        return newDots;
                      });
                      setCursor((val) =>
                        val < selectedDots.length - 1 ? ++val : 0
                      );
                    }}
                  ></span>
                ))}
              </div>
              <div className="button-area">
                <div className="d-flex flex-row">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm flex-grow-1 sub-button"
                    onClick={() => {
                      setSelectedDots((dots) => {
                        const newDots = [...dots];
                        newDots[cursor].color = undefined;
                        return newDots;
                      });
                      setCursor((val) =>
                        val !== 0 ? --val : selectedDots.length - 1
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                  <Dropdown drop="up" className="actions">
                    <Dropdown.Toggle
                      variant="secondary"
                      size="sm"
                      id="actions"
                      className="sub-button ms-1"
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          clearSelectedDots(num);
                        }}
                      >
                        すべての選択解除
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          initCorrectDots(num);
                          clearSelectedDots(num);
                          clearResults();
                          setGameState("playing");
                        }}
                      >
                        最初から始める
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          navigate("/select-mode");
                        }}
                      >
                        モード選択
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        やめる
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => {
                          setShowHelp(true);
                        }}
                      >
                        ヘルプ表示
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setShowVersion(true);
                        }}
                      >
                        バージョン表示
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <button
                  type="button"
                  className={`mt-1 btn btn-primary flex-grow-1${
                    isSubmit() ? "" : " disabled"
                  }`}
                  onClick={() => {
                    submit();
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} size="2x" />
                </button>
              </div>
            </div>
          </div>
          <p className="text-center m-0 p-2">
            ８回以内に答えを見つけましょう！
          </p>
          <p className="text-center m-0 p-1 text-muted small">
            © 2022 Usalabo, inc
          </p>
        </div>
        <Overlay show={gameState === "clear"}>
          <div className="text-center h-100 d-flex flex-column justify-content-center align-items-center mb-3">
            <div className="overlay-body">
              <h3 className="mb-5">おめでとう！正解！！</h3>
              <div className="d-flex flex-column">
                <button
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={() => {
                    initCorrectDots(num);
                    clearSelectedDots(num);
                    clearResults();
                    setGameState("playing");
                  }}
                >
                  もう一回やる
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  やめる
                </button>
              </div>
            </div>
          </div>
        </Overlay>
        <Overlay show={gameState === "gameover"} color="dark">
          <div className="text-center h-100 d-flex flex-column justify-content-center align-items-center mb-3">
            <div className="overlay-body">
              <h3 className="mb-5 text-light">げーむおーばー</h3>
              <p className="text-light">残念。正解の色は</p>
              <div className="correct-viwer mb-3">
                {correctDots.map(({ color }, idx) => (
                  <span key={idx} className={`dot dot-${color}`}></span>
                ))}
              </div>
              <p className="text-light mb-5">でした。</p>

              <div className="d-flex flex-column">
                <button
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={() => {
                    initCorrectDots(num);
                    clearSelectedDots(num);
                    clearResults();
                    setGameState("playing");
                  }}
                >
                  もう一回やる
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  やめる
                </button>
              </div>
            </div>
          </div>
        </Overlay>
        <Modal
          show={showHelp}
          onHide={() => {
            setShowHelp(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>ヘルプ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 className="mb-4">ヒット アンド ブロー</h3>
            <p>
              おっと、初めての方でしょうか？
              <br />
              簡単ですがゲームの説明をさせてもらいますね。
            </p>
            <ol>
              <li>コンピューターがランダムで選んだ{num}つの色を当てましょう。</li>
              <li>
                重複しない{num}つの色を選択して <FontAwesomeIcon icon={faCheck} />{" "}
                を押すと、正解の色と比較した結果が返ってきます。
              </li>
              <li>
                <b>色</b>と<b>位置</b>が一致すれば<b>Hit</b>。<b>色</b>
                だけが一致した場合は<b>Blow</b>。
              </li>
              <li>
                <b>Hit</b>と<b>Blow</b>の個数をヒントに色を当てましょう。
              </li>
              <li>8回以内に当てられなければゲームオーバーです。</li>
            </ol>
            <p>ルールは簡単ですよね？早速ですがプレイしてみましょう！</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowHelp(false);
              }}
            >
              閉じる
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showVersion}
          onHide={() => {
            setShowVersion(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>バージョン</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <h3 className="mb-4">ヒット アンド ブロー</h3>
              <img
                src="/logo512.png"
                className="img-fluid logo mb-3"
                alt="ロゴ"
              />
              <p>version: {version}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowVersion(false);
              }}
            >
              閉じる
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Game;
