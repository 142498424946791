import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GameModeContext } from "../../game-mode-store/GameModeStore";

const SelectMode: FC = () => {
  const navigate = useNavigate();
  const { setNum } = useContext(GameModeContext);
  return (
    <div className="h-100">
      <div className="d-flex flex-column text-center justify-content-center h-100">
        <h1 className="mb-4">モード選択</h1>
        <p className="mb-4">難易度を選択してください</p>
        <div>
          <button
            type="button"
            className="btn btn-primary ps-5 pe-5 mb-2"
            onClick={() => {
              setNum(2);
              navigate("/game");
            }}
          >
            かんたん<small>(2つ)</small>
          </button>
          <br />
          <button
            type="button"
            className="btn btn-primary ps-5 pe-5 mb-2"
            onClick={() => {
              setNum(3);
              navigate("/game");
            }}
          >
            ふつう<small>(3つ)</small>
          </button>
          <br />
          <button
            type="button"
            className="btn btn-primary ps-5 pe-5 mb-2"
            onClick={() => {
              setNum(4);
              navigate("/game");
            }}
          >
            むずい<small>(4つ)</small>
          </button>
          <br />
          <button
            type="button"
            className="btn btn-primary ps-5 pe-5"
            onClick={() => {
              setNum(5);
              navigate("/game");
            }}
          >
            ちょーむず<small>(5つ)</small>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectMode;
